import React from 'react';
import { serviceTelHref, serviceTel } from '../../consts';
import {
  FooterLogo,
  FooterLogoDesktop,
  InstagramIcon,
  FacebookIcon,
  TelegramIcon,
  CallIcon,
} from "../svg";
import c from './footer.module.scss';

const Footer = () => {
  return (
    <footer className={c.footer}>
      <div className={c.content}>
        <div className={c.left}>
          <div className={c.logo_container}>
            <FooterLogo className={c.footer_logo} />
            <FooterLogoDesktop className={c.footer_logo_desktop} />
          </div>
          <div className={c.social}>
            <a className={c.call_icon} href={serviceTelHref} >
              <CallIcon />
            </a>
            <a href="https://www.instagram.com/carfix.if/profilecard/?igsh=MTZvaHEwNnZ1ZzR5cQ==&utm_source=site&utm_medium=profile&utm_campaign=autopromo" target="_blank" rel="noreferrer">
              <InstagramIcon className={c.icon} />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61564832812383&utm_source=site&utm_medium=profile&utm_campaign=autopromo" target="_blank" rel="noreferrer">
              <FacebookIcon className={c.icon} />
            </a>
            <a href="/" target="_blank" rel="noreferrer">
              <TelegramIcon className={c.icon} />
            </a>
          </div>
          <div className={c.address}>
            вул. Коновальця, 148 <span>Б</span>
          </div>
          <div className={c.copyright}>
            <span>©</span> {new Date().getFullYear()} – carfix
          </div>
        </div>
        <div className={c.right}>
          <div className={c.links}>
            <div className={c.link_block}>
              <a href="#services">послуги</a>
            </div>
            <div className={c.link_block}>
              <a href="#done-work">Виконані роботи</a>
            </div>
            <div className={c.link_block}>
              <a href='#application'>залишити заявку</a>
            </div>
          </div>
          <div className={c.info}>
            <a className={c.item} href={serviceTelHref}>{serviceTel}</a>
            <p className={c.item}>car.fix.if@gmail.com</p>
            <p className={c.address}>вул. Коновальця, 148 <span>Б</span></p>
          </div>
        </div>
      </div>
  </footer>
  ); 
}

export default Footer;