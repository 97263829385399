import { useEffect, useState } from "react";
import { getIsMobile } from "../helpers/getIsmobile";

export const useMobile = () => {
  const [isMobile, setIsMobile] = useState(getIsMobile());
  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(getIsMobile());
    });
  });

  return isMobile;
}