import React from 'react';
import c from './hero.module.scss';

const Hero = () => {
  return (
    <div className={c.hero}>
      <h1>сто повного циклу</h1>
      <h2>
        ремонт <span>/</span> відновлення <span>/</span> обслуговування
      </h2>
      <div className={c.image_container}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1344"
        height="736"
        viewBox="0 0 1344 736"
        fill="none"
      >
        <g filter="url(#filter0_d_20_248)">
          <path
            d="M345.676 140C259.745 143.311 182.673 199.464 161.692 283.228C157.407 300.332 153.751 316.697 150.691 332.836L190.853 349.933C206.428 356.564 220.356 366.533 231.655 379.136C247.355 396.65 241.17 424.41 219.52 433.601L140 467.362C150.218 541.514 218.196 596.58 296.176 595.855H378.772L420.282 547.239C433.445 531.823 451.673 521.605 471.692 518.42C490.053 515.499 506.676 529.686 506.676 548.278V595.855L714.068 595.915C716.432 595.915 718.473 594.261 718.963 591.948L742.415 498.608C743.64 492.826 748.743 488.69 754.653 488.69L1011.47 489.19C1051.56 489.19 1085.85 460.402 1092.79 420.925L1100.8 375.355H768.761C762.926 375.355 762.805 375.834 761.51 380.938L761.51 380.939L761.426 381.271L740.561 472.494C739.315 478.249 734.224 482.355 728.335 482.355H619.671L619.676 482.345H355.71C325.513 482.345 299.62 459.356 300.095 429.236C300.669 392.865 307.121 358.75 317.176 325.345C329.941 282.942 370.295 255.845 414.676 255.845L590.11 253.787L615.676 142.056L478.243 140.892L432.069 194.97C418.906 210.386 400.679 220.604 380.659 223.789C362.298 226.71 345.676 212.523 345.676 193.931V140Z"
            fill="#DCF44F"
          />
          <path
            d="M780.579 211.613C790.793 171.355 821.254 140.855 862.293 140.855H1195.18C1200.35 140.855 1204.16 145.675 1202.97 150.7L1178.61 253.439H770.705L780.579 211.613Z"
            fill="#DCF44F"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_20_248"
            x="0"
            y="0"
            width="1343.19"
            height="735.915"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="70" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.862745 0 0 0 0 0.956863 0 0 0 0 0.309804 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_20_248"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_20_248"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
      <img src='/assets/img/hero_car.png' alt="hero" /> 
      </div>
    </div>
  );
}

export default Hero;