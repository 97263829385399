import React from 'react';
import Form from '../form';
import c from './bottom-application.module.scss';

const BottomApplication = () => {
  return (
    <div className={c.container}>
      <div className={c.form_block}>
        <Form>
          <div className={c.head}>
            <h2>залишай заявку</h2>
            <p className={c.dek}>
              і ми <span>перетелефонуємо</span> протягом <span>10 хвилин</span>
            </p>
          </div>
        </Form>
        <img className={c.porsche} src="/assets/img/img-porsche.png" alt="porsche" />
        <img className={c.audi} src="/assets/img/img-audi.png" alt="audi" />
      </div>
    </div>
  );
}

export default BottomApplication;