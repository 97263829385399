import React from 'react';
import Form from '../form';
import c from './application.module.scss';

const Application = () => {
  return (
    <div id="application" className={c.application}>
      <div className={c.container}>
        <div className={c.content}>
          <h2>залишай заявку</h2>
          <p className={c.dek}>
            і ми <span>перетелефонуємо</span> протягом <span>10 хвилин</span>
          </p>
          <Form />
        </div>
        <img className={c.car_1} src="/assets/img/application_car_1.png" alt="car_1" />
        <img className={c.car_2} src="/assets/img/application_car_2.png" alt="car_2" />
      </div>
    </div>
  );
}

export default Application;