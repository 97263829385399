import React from 'react';
import cn from 'classnames';
import c from './button.module.scss';

const Button = ({text, action, onClick, type, children, className = '', name, id, Component = 'button', formType, ...rest}) => {
  return (
    <Component
      {...(name ? {name} : {})}
      {...(id ? {id} : {})}
      {...(formType ? {type: formType} : {})}
      {...(onClick ? {onClick} : {})}
      className={cn(c.button, c[type], className)}
      {...rest}
    >
      {children ? children : text}
    </Component>
  );
};

export default Button;