import React from 'react';
import c from './map.module.scss';

const Map = () => {
  return (
    <div className={c.map}>
      <img src='/assets/img/map.png' alt='map' />
    </div>
  )
};

export default Map;