import React from 'react';
import cn from 'classnames';
import c from './container.module.scss';

const Container = ({ children, className }) => {
  return (
    <div className={cn(c.container, className)}>{children}</div>
  );
}

export default Container;