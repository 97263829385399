import React from 'react';
import {
  DiagnosticIcon,
  SettingsIcon,
  TransmissionIcon,
  EngineIcon,
  GearIcon,
  WheelIcon,
  PaintingIcon,
  RepairIcon,
  ConeIcon,
} from "../svg";
import c from './services.module.scss';

const left = [
  {Icon: DiagnosticIcon, title: '/Діагностика авто'},
  {Icon: SettingsIcon, title: '/Сервісне обслуговування'},
  {Icon: TransmissionIcon, title: '/ремонт ходової'},
  {Icon: EngineIcon, title: '/Ремонт двигунів, Автоелектрика', subtitle: 'Чіп тюнінг, Видалення EGR, ПРошивка ключа, ремонт проводки'},
];

const right = [
  {Icon: GearIcon, title: '/обслуговування та ремонт автоматичних та механічних кпп'},
  {Icon: WheelIcon, title: '/Шиномонтаж'},
  {Icon: ConeIcon, title: '/Рихтування авто після дтп'},
  {Icon: PaintingIcon, title: '/Підготовка до покраски та покраска авто'},
  {Icon: RepairIcon, title: '/Видалення вмятин'},
]

const Services = () => {
  return (
    <div id="services" className={c.services}>
      <h1>
        Наші <br /> Послуги
        <img src='/assets/img/services.png' alt="services" />
      </h1>
      <div className={c.content}>
        <div className={c.left}>
          {left.map(({Icon, title, subtitle}) => {
            return (
              <div key={title} className={c.listItem}>
                <Icon />
                <h3>{title}
                  {subtitle && (
                    <>
                    <br />
                    <p>{subtitle}</p>
                    </>
                  )}
                </h3>
              </div>
            )
          })}
        </div>
        <div className={c.right}>
          {right.map(({Icon, title, subtitle}) => {
            return (
              <div key={title} className={c.listItem}>
                <Icon />
                <h3>{title}
                  {subtitle && <span>{subtitle}</span>}
                </h3>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Services;