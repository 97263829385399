import React, {useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import './style.css';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import c from './done-work.module.scss';

const DoneWork = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div id="done-work" className={c.container}>
      <h1>
        Виконані
        <br />
        роботи
        <img src="/assets/img/arrow.png" alt="arrow" />
      </h1>
      <div>
        <Swiper
          lazyPreloadPrevNext
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper2"
        >
          {Array.from({ length: 24 }).map((_, index) => {
            return (
              <SwiperSlide key={index}>
                <img src={`/assets/img/work-slider/work_${index + 1}.jpeg`} />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <Swiper
          lazyPreloadPrevNext
          onSwiper={setThumbsSwiper}
          spaceBetween={20}
          slidesPerView={3}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
          breakpoints={{
            1040: {
              slidesPerView: 4,
            },
            0: {
              slidesPerView: 3,
            },
          }}
        >
          {Array.from({ length: 24 }).map((_, index) => {
            return (
              <SwiperSlide key={index}>
                <img src={`/assets/img/work-slider/work_${index + 1}.jpeg`} />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default DoneWork;