import React from 'react';
import Header from './components/header';
import Footer from './components/footer';
import Hero from './components/hero';
import Sale from './components/sale';
import Container from './components/container';
import Application from './components/application';
import Services from './components/services';
import DoneWork from './components/done-work';
import BottomApplication from './components/bottom-application';
import Map from './components/map';
import './styles/global.scss';
import './styles/fonts.scss';

function App() {
  return (
    <div>
      <Header />
        <Container className="main-content">
          <div className='inner-content'>
            <Hero />
            <Sale />
            <Application />
            <Services />
            <DoneWork/>
            <BottomApplication />
            <Map />
            <Footer />
          </div>
        </Container>
    </div>
  );
}

export default App;
