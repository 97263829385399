import React, { useState } from 'react';
import cn from 'classnames';
import { serviceTel, serviceTelHref } from '../../consts';
import { Close, Hamburger } from '../svg';
import Button from '../button';
import { useMobile } from '../../hooks/useMobile';
import c from './header.module.scss';
import Container from '../container';

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  const isMobile = useMobile();

  const headerData = [
    {title: 'Послуги', link: '#services'},
    {title: 'Виконані роботи', link: '#done-work'},
    {title: 'Контакти', link: '#footer'},
  ];

  const getMobileContent = () => {
    if(expanded) {
      return (
        <div className={c.mobile_links}>
          {headerData.map(({title, link}) => {
            return (
              <a key={title} href={link}>
                {title}
              </a>
            );
          })}
        </div>
      )
    }

    return (
      <>
        <Button Component="a" href="#application" type="primary" text='Залишити заявку' />
      </>
    )
  }

  const getContent = () => {
    return isMobile ? (
      <>
        <div className={c.left_mobile}>
          <img
            className={cn(c.img, {
              [c.active]: expanded,
            })}
            src="/assets/img/app_logo.png"
            alt="logo"
          />
          {getMobileContent()}
        </div>
        <div className={c.right_mobile}>
          {expanded ? (
            <Close onClick={() => setExpanded(false)} />
          ) : (
            <Hamburger onClick={() => setExpanded(true)} />
          )}
        </div>
      </>
    ) : (
      <>
      <div className={c.left}>
        <img
              className={cn(c.img)}
              src="/assets/img/app_logo.png"
              alt="logo"
            />
      </div>
      <div className={c.right}>
        <Button Component='a' href="#application" type="primary" text='Залишити заявку' />
        {headerData.slice(0, 2).map(({title, link}) => {
          return (
            <a key={title} href={link}>
              {title}
            </a>
          );
        })}
        <div className={c.divider} />
        <a href={serviceTelHref}>{serviceTel}</a>
      </div>
      </>
    );
  }

  return (
    <Container>
      <header className={c.header}>
        {getContent()}
      </header>
      <div className={c.bottom_border} />
    </Container>
  ); 
}

export default Header;